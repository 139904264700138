import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';

const ProjectContext = createContext({
    projectData: null,
    tasksData: null,
    employees: null,
    companyId: null,
    refreshData: () => { },
    loadingStates: {}
});

export const ProjectDataProvider = ({ children }) => {
    const [employees, setEmployees] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [jobsPerEmp, setJobsPerEmp] = useState([]);
    const [projectData, setProjectData] = useState([]);
    const [tasksData, setTasksData] = useState([]);
    const [teams, setTeam] = useState([]);
    const [roles, setRoles] = useState([]);
    const [company, setCompany] = useState([]);
    const [loadingStates, setLoadingStates] = useState({
        employees: true,
        projectData: true,
        tasksData: true,
        jobs: true,
        teams: true,
        roles: true,
        company: true,
    });
    const companyId = localStorage.getItem("company_id");
    const [refreshProjectList, setRefreshProjectList] = useState(false);
    const userJob = localStorage.getItem("jobs_id");
    const employeeId = localStorage.getItem("id");
    const token = localStorage.getItem("token");

    const fetchJobsPerUser = useCallback(async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/jobs/${userJob}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const jobData = response.data.data;
            setJobsPerEmp(jobData);
        } catch (error) {
            return ("Error fetching jobs:", error);
        }
    }, [userJob]);

    const fetchEmployees = useCallback(async () => {
        setLoadingStates(prev => ({ ...prev, employees: true }));
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/employees/company/${companyId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setEmployees(response.data.data);
        } catch (error) {
            return ("Error fetching employees details:", error);
        } finally {
            setLoadingStates(prev => ({ ...prev, employees: false }));
        }
    }, [companyId]);

    const fetchProjectData = useCallback(async () => {
        setLoadingStates(prev => ({ ...prev, projectData: true }));
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/projects/company/${companyId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
                // {
                    // params: { employee_id: employeeId}

                // }
            );
            setProjectData(response.data.data);
        } catch (error) {
            return ("Error fetching project data:", error);
        } finally {
            setLoadingStates(prev => ({ ...prev, projectData: false }));
        }
    }, [companyId]);

    const fetchTasks = useCallback(async () => {
        setLoadingStates(prev => ({ ...prev, tasksData: true }));
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/tasks`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    params: { company_id: companyId }
                }
            );
            setTasksData(response.data.data);
        } catch (error) {
            return ("Error fetching tasks:", error);
        } finally {
            setLoadingStates(prev => ({ ...prev, tasksData: false }));
        }
    }, [companyId]);

    const fetchJobs = useCallback(async () => {
        setLoadingStates(prev => ({ ...prev, jobs: true }));
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/jobs/company/${companyId}`,
                {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
            );
            setJobs(response.data.data);
        } catch (error) {
            return ("Error fetching jobs:", error);
        } finally {
            setLoadingStates(prev => ({ ...prev, jobs: false }));
        }
    }, [companyId]);

    const fetchTeams = useCallback(async () => {
        setLoadingStates(prev => ({ ...prev, teams: true }));
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/teams/company/${companyId}`,
                {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
            );
            setTeam(response.data.data);
        } catch (error) {
            return ("Error fetching teams:", error);
        } finally {
            setLoadingStates(prev => ({ ...prev, teams: false }));
        }
    }, [companyId]);

    const fetchRoles = useCallback(async () => {
        setLoadingStates(prev => ({ ...prev, roles: true }));
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/roles`,
                {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
            );
            setRoles(response.data.data);
        } catch (error) {
            return ("Error fetching roles:", error);
        } finally {
            setLoadingStates(prev => ({ ...prev, roles: false }));
        }
    }, [companyId]);

    const fetchCompany = useCallback(async () => {
        setLoadingStates(prev => ({ ...prev, company: true }));
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/companies/${companyId}`,
                {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
            );
            setCompany(response.data.data);
            localStorage.setItem("companyImage", response.data.data.company_image);
        } catch (error) {
            return ("Error fetching company:", error);
        } finally {
            setLoadingStates(prev => ({ ...prev, company: false }));
        }
    }, [companyId]);

    useEffect(() => {
        fetchEmployees();
        fetchProjectData();
        fetchTasks();
        fetchJobs();
        fetchTeams();
        fetchRoles();
        fetchCompany();
    }, [fetchEmployees, fetchProjectData, fetchTasks, fetchJobs, fetchTeams, fetchRoles, fetchCompany, refreshProjectList]);

    const refreshData = useCallback((fetchFunctionName) => {
        switch (fetchFunctionName) {
            case 'fetchEmployees':
                fetchEmployees();
                break;
            case 'fetchProjectData':
                fetchProjectData();
                break;
            case 'fetchTasks':
                fetchTasks();
                break;
            case 'fetchJobs':
                fetchJobs();
                break;
            case 'fetchTeams':
                fetchTeams();
                break;
            case 'fetchCompany':
                fetchCompany();
                break;
            default:
                return ('Invalid fetch function name');
        }
    }, [fetchEmployees, fetchProjectData, fetchTasks, fetchJobs, fetchTeams, fetchCompany]);


    const contextValue = useMemo(() => ({
        projectData,
        tasksData,
        employees,
        jobs,
        teams,
        roles,
        companyId,
        jobsPerEmp,
        company,
        refreshData,
        loadingStates
    }), [projectData, tasksData, employees, teams, jobs, roles, companyId, company, jobsPerEmp, refreshData, loadingStates]);

    return (
        <ProjectContext.Provider value={contextValue}>
            {children}
        </ProjectContext.Provider>
    );
};

export default ProjectContext;
