import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Header from "./components/general/header";
import Sidebar from "./components/general/sidebar";
import Footer from "./components/general/footer";
import OverlayWarning from "./components/general/OverlayWarning";
import LoadingOverlay from "./components/general/LoadingOverlay";
import Protected from "./pages/Protected";
import NotFound from "./pages/NotFound";
import Unauthorized from "./components/general/403Warning";
import { ProjectDataProvider } from "./context/ProjectContext";

// Lazy loading components
const Dashboard = React.lazy(() => import("./pages/dashboard"));
const Login = React.lazy(() => import("./pages/login"));
const Profile = React.lazy(() => import("./pages/profile"));
const Company = React.lazy(() => import("./pages/companyProfile"));
const Project = React.lazy(() => import("./pages/Project"));
const Maintenance = React.lazy(() => import("./pages/Maintenance"));
const ManageUser = React.lazy(() => import("./pages/ManageUser"));
const ManageProject = React.lazy(() => import("./pages/ManageProject"));
const DocumentArchive = React.lazy(() => import("./pages/DocumentArchive"));
const PendingTask = React.lazy(() => import("./pages/PendingTask"));
const UpdateProject = React.lazy(() => import("./pages/SubPages/UpdateProject"));
const UpdateTask = React.lazy(() => import("./pages/SubPages/UpdateTask"));
const UpdateUser = React.lazy(() => import("./pages/SubPages/UpdateUser"));
const Attendance = React.lazy(() => import("./pages/attendance"));
const LogHistory = React.lazy(() => import("./pages/logHistory"));
const LogHistoryDetail = React.lazy(() => import("./pages/logHistoryDetail"));
const Absent = React.lazy(() => import("./content/absent"));
const Setting = React.lazy(() => import("./pages/Setting"));
const ProjectDetail = React.lazy(() => import("./pages/SubPages/ProjectDetail"));
const SalesDetail = React.lazy(() => import("./pages/SubPages/SalesDetail"));
const DocumentDetail = React.lazy(() => import("./pages/SubPages/DocumentDetail"));
const DailyTask = React.lazy(() => import("./pages/dailyTask"));
const TaskDetail = React.lazy(() => import("./pages/taskDetail"));
const EmployeePerformance = React.lazy(() => import("./pages/EmployeePerformance"));
const Coba = React.lazy(() => import("./pages/coba"));
const SingleTask = React.lazy(() => import("./pages/singleTask"));
const Register = React.lazy(() => import("./pages/register"));
const Reimbursement = React.lazy(() => import("./pages/Reimbursement"));
const ManagementSales = React.lazy(() => import("./pages/ManagementSales"));
const ManagementSalesClient = React.lazy(() => import("./pages/ManagementSalesClient"));
const ForgotPassword = React.lazy(() => import("./pages/forgotPassword"));
const UserRegistration = React.lazy(() => import("./pages/UserRegistration"));
const UserProjectDetail = React.lazy(() => import("./pages/UserProjectDetail"));
const Scheduler = React.lazy(() => import("./pages/Scheduler"));
const Notification = React.lazy(() => import("./pages/notification"));

function App() {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const roleId = localStorage.getItem("role_id");

    const handleClose = () => {
      setIsVisible(false);
    };

    const parseDateString = (dateString) => {
      const isoDateString = dateString.replace(' ', 'T');
      return new Date(isoDateString).getTime();
    };

    const checkTokenExpiration = () => {
      const tokenExpirationString = localStorage.getItem("expired_at");

      if (tokenExpirationString) {
        const tokenExpiration = parseDateString(tokenExpirationString);
        const currentTime = new Date().getTime();

        if (currentTime > tokenExpiration) {
          setShowSessionExpiredModal(true);
        }
      }
    };

    useEffect(() => {
      checkTokenExpiration(); // Initial check on mount

      const interval = setInterval(() => {
        checkTokenExpiration(); // Repeatedly check every minute
      }, 30000); // Check every 60 seconds

      return () => clearInterval(interval); // Clean up on unmount
    }, []);

    const checkTeam = () => {
      const team = localStorage.getItem("teams");
      if (team === false || team === null) {
        setShowAlert(true); 
      }
    };

    useEffect(() => {
      checkTeam();
    }, []);

    const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle);
    };

  const accessPermissions = localStorage.getItem("access_permissions") ? JSON.parse(localStorage.getItem("access_permissions")) : {};
  
  const routes = [
    { path: "/dashboard", component: Dashboard, access: accessPermissions.access_dashboard },
    { path: "/profile/:id", component: Profile, access: true },
    { path: "/company/:id", component: Company, access: true },
    { path: "/project", component: Project, access: accessPermissions.access_project },
    { path: "/maintenance", component: Maintenance, access: accessPermissions.access_project },
    { path: "/daily-task", component: DailyTask, access: accessPermissions.access_additional_tasks },
    { path: "/task-adhoc", component: SingleTask, access: accessPermissions.access_adhoc_tasks },
    { path: "/sales-detail/:clientName/:productClientId", component: SalesDetail, access: accessPermissions.access_sales_management },
    // { path: "/task-detail/:id/", component: TaskDetail, access: accessPermissions.project },
    { path: "/document-archive", component: DocumentArchive, access: accessPermissions.access_document_archive },
    { path: "/document-detail/:id/:folderName", component: DocumentDetail, access: accessPermissions.access_document_archive },
    // { path: "/manageProject", component: ManageProject, access: accessPermissions.project },
    { path: "/manageUser", component: ManageUser, access: accessPermissions.access_manage_user },
    { path: "/attendance", component: Attendance, access: accessPermissions.access_attendance },
    // { path: "/absent", component: Absent, access: accessPermissions.attendance },
    { path: "/project-detail/:id", component: ProjectDetail, access: accessPermissions.access_project},
    // { path: "/update-task/:id", component: UpdateTask, access: accessPermissions.project },
    // { path: "/update-project/:id", component: UpdateProject, access: accessPermissions.project },
    // { path: "/update-user/:id", component: UpdateUser, access: accessPermissions.manageUser },
    { path: "/pending-task", component: PendingTask, access: accessPermissions.access_tasks },
    { path: "/log-history", component: LogHistory, access: true },
    { path: "/log-history/:id", component: LogHistoryDetail, access: true },
    // { path: "/log-history/:id/:startMonth/:finishMonth", component: LogHistoryDetail, access: true },
    { path: "/performance", component: EmployeePerformance, access: accessPermissions.access_performance_karyawan },
    { path: "/Setting", component: Setting, access: accessPermissions.access_settings },
    { path: "/reimbursement", component: Reimbursement, access: true },
    { path: "/management-sales", component: ManagementSales, access: accessPermissions.access_sales_management },
    { path: "/management-sales/:id", component: ManagementSalesClient, access: accessPermissions.access_sales_management },
    { path: "/scheduler", component: Scheduler, access: true },
    { path: "/notifications", component: Notification, access: true },
  ];

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <ChakraProvider>
      <ProjectDataProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Suspense fallback={<LoadingOverlay />}><Login /></Suspense>} />
            <Route path="/register/:type" element={<Suspense fallback={<LoadingOverlay />}><Register /></Suspense>} />
            <Route path="/user/:encodedDetail" element={<Suspense fallback={<LoadingOverlay />}><UserRegistration /></Suspense>} />
            <Route path="/project/:encodedDetail" element={<Suspense fallback={<LoadingOverlay />}><UserProjectDetail /></Suspense>} />
            <Route path="/forgot-password" element={<Suspense fallback={<LoadingOverlay />}><ForgotPassword /></Suspense>} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            {routes.map((route, index) => (
              <Route 
                key={index}
                path={route.path}
                element={
                  <div className="grid-container">
                    {/* Header */}
                    <Header OpenSidebar={OpenSidebar} />

                    {/* Sidebar */}
                    <Sidebar
                      openSidebarToggle={openSidebarToggle}
                      OpenSidebar={OpenSidebar}
                      accessData={accessPermissions}
                    />

                    {/* {roleId == 2 && showAlert && isVisible && (
                      <div className="alert-overlay">
                        <Alert status='warning'>
                          <Flex direction={"row"} justifyContent={"space-between"} alignItems={"center"} w={"full"}>
                            <Flex direction={"row"} gap={2} alignItems={"center"}>
                              <AlertIcon />
                              <AlertDescription>
                                Selamat datang di aplikasi KejarTugas! Silakan kelola divisi terlebih dahulu. <Link color={"blue.400"} href='/manageUser'>Kelola Divisi</Link>
                              </AlertDescription>
                            </Flex>
                            <CloseButton
                              alignSelf='flex-start'
                              onClick={handleClose}
                            />
                          </Flex>


                        </Alert>
                      </div>
                    )} */}


                    {/* Protected Route Component */}
                    <Protected
                      access={route.access}
                      Page={() => (
                        <Suspense fallback={<LoadingOverlay />}>
                          <route.component />
                        </Suspense>
                      )}
                    />

                    {/* Uncomment if you want to use Footer */}
                    {/* <Footer /> */}
                  </div>
                }
              />
            ))}

            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ProjectDataProvider>
      {/* Show modal if session expired */}
      {showSessionExpiredModal && (
        <OverlayWarning
          title="Session Expired"
          message="Sesi Anda telah berakhir. Silakan masuk lagi."
          onClose={() => window.location.href = '/'}
        />
      )}
    </ChakraProvider>
  );
}

export default App;
