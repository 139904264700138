import { create } from 'zustand';
import axios from 'axios';

// Create a Zustand store for notifications
const useNotificationStore = create((set) => ({
  notifications: [],
  unreadCount: 0,
  isLoading: false,
  error: null,
  
  setNotifications: (notifications) => 
    set({ 
      notifications,
      unreadCount: notifications.filter(n => !n.is_read).length 
    }),
    
  updateNotification: (notificationId, updates) => 
    set((state) => {
      const updatedNotifications = state.notifications.map(notif =>
        notif.id === notificationId ? { ...notif, ...updates } : notif
      );
      return {
        notifications: updatedNotifications,
        unreadCount: updatedNotifications.filter(n => !n.is_read).length
      };
    }),
}));

// Custom hook for notification management
const useNotifications = () => {
  const { 
    notifications, 
    unreadCount, 
    isLoading,
    error,
    setNotifications, 
    updateNotification 
  } = useNotificationStore();

  // Fetch all notifications
  const fetchNotifications = async (employeeId) => {
    useNotificationStore.setState({ isLoading: true, error: null });
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/notifications/employees/${employeeId}`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setNotifications(response.data.data);
    } catch (error) {
      useNotificationStore.setState({ 
        error: error.response?.data?.message || 'Failed to fetch notifications' 
      });
    } finally {
      useNotificationStore.setState({ isLoading: false });
    }
  };

  // Mark notification as read
  const markAsRead = async (notificationId) => {
    useNotificationStore.setState({ isLoading: true, error: null });
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/notifications/${notificationId}/read`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      return true;
    } catch (error) {
      useNotificationStore.setState({ 
        error: error.response?.data?.message || 'Failed to mark notification as read' 
      });
      return false;
    } finally {
      useNotificationStore.setState({ isLoading: false });
    }
  };

  // Mark all notifications as read
  const markAllAsRead = async (employeeId) => {
    useNotificationStore.setState({ isLoading: true, error: null });
    try {
      await axios.put(`/api/notifications/${employeeId}/read-all`);
      const updatedNotifications = notifications.map(notif => ({
        ...notif,
        is_read: true
      }));
      setNotifications(updatedNotifications);
      return true;
    } catch (error) {
      useNotificationStore.setState({ 
        error: error.response?.data?.message || 'Failed to mark all notifications as read' 
      });
      return false;
    } finally {
      useNotificationStore.setState({ isLoading: false });
    }
  };

  return {
    notifications,
    unreadCount,
    isLoading,
    error,
    fetchNotifications,
    markAsRead,
    markAllAsRead
  };
};

export default useNotifications;